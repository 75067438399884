<div
    [ngClass]="{
        'main-container-spinner': !minimumHeight,
        'more-space': minimumHeight,
        'override-space': overrideSpace,
        'inside-button': insideButton,
        row: !insideButton,
        'loading-table': insideTable
    }"
    style="position: relative"
>
    <mat-spinner
        class="main-spinner"
        diameter="{{ diameterSize ? diameterSize : 40 }}"
        [mode]="spinnerMode"
        [class.button-spinner]="insideButton"
    ></mat-spinner>
    @if (!minimumHeight && !insideButton && !noMessage) {
        <div class="text custom-text-color">
            {{ message ? message : ('shared.miscellaneous.loading' | transloco) }}
        </div>
    }
    @if (minimumHeight && !insideButton && !noMessage) {
        <div class="text small custom-text-color">
            {{ message ? message : ('shared.miscellaneous.loading' | transloco) }}
        </div>
    }
    @if (insideButton && !noMessage) {
        <div class="insideButton">
            {{ message ? message : ('shared.miscellaneous.loading' | transloco) }}
        </div>
    }
</div>
