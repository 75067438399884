@if (!multiple) {
    <mat-form-field class="pwo-form picker">
        <input
            matInput
            [required]="required"
            placeholder="{{ placeholder }}"
            [value]="value ? value.title : ''"
            [ngClass]="{ 'input-invalid': condition === true }"
            readonly
        />
        @if (!readonly) {
            @if (!value || !value.title) {
                <mat-chip (click)="openProgramSelection()" [ngClass]="{ invalid: condition === true }">
                    <i class="a-icon a-icon--plus"></i>
                </mat-chip>
            } @else {
                <ceres-button (click)="removeSelected(null)" [layout]="'picker'">
                    <i class="material-icons">delete</i>
                </ceres-button>
            }
        }
    </mat-form-field>
} @else {
    <mat-form-field class="w-100">
        <mat-chip-grid #chipList>
            @for (program of value; track program) {
                <mat-chip (removed)="removeSelected(program)">
                    {{ program.title }}
                    @if (!readonly) {
                        <mat-icon matChipRemove>cancel</mat-icon>
                    }
                </mat-chip>
            }
            @if (!readonly) {
                <mat-chip (click)="openProgramSelection(true)">
                    <i class="a-icon a-icon--plus"></i>
                </mat-chip>
            }
            <input [disabled]="readonly" [matChipInputFor]="chipList" placeholder="{{ placeholder }}" readonly />
        </mat-chip-grid>
    </mat-form-field>
}
